import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';

import { arrayMoveImmutable } from 'array-move';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import Icon from '@components/Icon';
import { Favorite, Table, TableActionBtn } from '@element/index';
import helper from '@utils/helper';
import { formatPrettyValue, I18n } from '@utils/index';

import { jumpPage } from './utils';

import styles from './index.module.css';

const DragHandle = SortableHandle(() => (
  <Icon name="dragTable" className={styles.iconSize} />
));
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

function ListWeb (props) {
  const router = useRouter();
  const {
    dataSource,
    loading,
    page,
    isEdit,
    emptyText,
    selecteChange,
    selectedRowKeys,
    setDataSource,
    onTopping,
    showFavorite
  } = props;
  // page: 'ZONES_PAGE' | 'MARKET_PAGE' | 'HOME_HOT' | 'HOME_NEW' 板块详情 市场列表 首页-热门币种 首页-kiki新币

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.coinCode === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const rowSelection = {
    type: 'checkbox',
    hideSelectAll: true,
    onChange: selecteChange,
    selectedRowKeys
  };

  const columns = [
    {
      // 名称
      title:
        page === 'ZONES_PAGE'
          ? I18n.t('asset_coin')
          : I18n.t('choice_header_name'),
      dataIndex: 'baseCurrency',
      key: 'baseCurrency',
      className: 'drag-visible',
      sorter: (a, b) =>
        page === 'MARKET_PAGE' || page === 'ZONES_PAGE'
          ? a.coinCode.slice(0, 1).charCodeAt(0) -
          b.coinCode.slice(0, 1).charCodeAt(0)
          : false,
      showSorterTooltip: false,
      width: '20%',
      render: (_, record) => {
        const coinArr = record?.coinCode?.split('_') ?? [];
        return (
          <div className={styles.coinCol}>
            <div
              className={cn(styles.favoriteIcon, {
                [styles.hide]: isEdit || !showFavorite
              })}
            >
              <Favorite
                className={styles.start}
                code={record?.coinCode}
                favoriteType="symbol"
              />
            </div>
            <div className={styles.coinIcon}>
              {record.picturePath && (
                <Image
                  src={record.picturePath}
                  className="image_borderRadius_32"
                  width={32}
                  height={32}
                  layout="fixed"
                  alt={'kikitrade'}
                />
              )}
            </div>
            <div className={styles.coinText}>
              <span className={cn(styles.coinName, 'DINPro-Medium')}>
                {page === 'HOME_NEW' ? record.name : coinArr[0]}
              </span>
              {page !== 'HOME_NEW' && (
                <span className={cn(styles.currency, 'DINPro-Medium')}>
                  /{coinArr[1]}
                </span>
              )}
            </div>
          </div>
        );
      }
    },
    {
      // 最新价
      title: I18n.t('MBP2W6W0'),
      dataIndex: 'currentData',
      key: 'currentData',
      sorter:
        page === 'MARKET_PAGE' || page === 'ZONES_PAGE'
          ? (a, b) => a.currentData - b.currentData
          : false,
      showSorterTooltip: false,
      width: '25%',
      className: 'drag-visible',
      render: (currentData, record) => {
        const [coin = '', currentDataCount = ''] = record?.currentDataText?.split(' ') || []
        return (
          <div className={styles.latestPriceCol}>
            <span className={cn(styles.latestPrice1, 'DINPro')}>
              {formatPrettyValue(record.formatPriceLast) || '--'}
            </span>
            <span className={cn(styles.latestPrice2, 'DINPro')}>
              {`${coin} ${formatPrettyValue(currentDataCount)}`}
            </span>
          </div>
        );
      }
    },
    {
      // 24h涨幅
      title: <span className="DINPro">{I18n.t('BWt0qjNE')}</span>,
      dataIndex: 'riseAndFall',
      key: 'riseAndFall',
      sorter:
        page === 'MARKET_PAGE' || page === 'ZONES_PAGE'
          ? (a, b) => a.riseAndFall - b.riseAndFall
          : false,
      showSorterTooltip: false,
      width: '20%',
      className: 'drag-visible',
      render: (riseAndFall) => {
        const className = cn(styles.increase, 'DINPro-Medium', {
          [styles.greenText]: riseAndFall > 0,
          [styles.redText]: riseAndFall < 0
        });
        return (
          <span className={className}>
            {riseAndFall > 0 ? '+' : ''}
            {helper.formatFixNumString(Number(riseAndFall ?? '0'), 2)}%
          </span>
        );
      }
    },
    {
      // 24h交易量
      title: <span className="DINPro">{I18n.t('YEZpiIrr')}</span>,
      dataIndex: 'volume',
      key: 'volume',
      sorter:
        page === 'MARKET_PAGE' || page === 'ZONES_PAGE'
          ? (a, b) => a.volume - b.volume
          : false,
      showSorterTooltip: false,
      width: '15%',
      className: 'drag-visible',
      render: (volume) => {
        console.log(volume, 'volume')
        return (
          <div className="DINPro" style={{ textAlign: (isEdit || page === 'MARKET_PAGE') ? 'left' : 'right' }}>
            {' '}
            {volume ? `${helper.formatVolume(parseFloat(volume).toFixed(2))}` : '--'}
          </div>
        );
      }
    }
  ];
  const actionCol = {
    // 操作
    title: I18n.t('action'),
    key: 'action',
    width: '10%',
    align: 'right',
    className: 'drag-visible',
    render: (record) => {
      if (isEdit) {
        return <DragHandle />;
      }
      return (
        <div>
          <TableActionBtn
            type="button"
            actionText={I18n.t('asset_exchange')}
            onClick={() => jumpPage(router, record.coinCode)}
          />
        </div>
      );
    }
  };

  const topping = {
    // 操作
    title: I18n.t('CMFCjU1t'),
    key: 'action1',
    // width: '10%',
    align: 'right',
    className: 'drag-visible',
    render: (record) => (
      <Icon
        name="topping"
        className={styles.iconSize}
        onClick={() => onTopping(record.coinCode)}
      />
    )
  };

  const getColumns = () => {
    if (isEdit) {
      columns.push(topping);
    }
    if (page === 'MARKET_PAGE') {
      columns.push(actionCol);
    }
    return columns;
  };
  console.log(dataSource, 'webdataSource')
  return (
    <Table
      loading={loading}
      dataSource={dataSource}
      rowSelection={isEdit ? rowSelection : null}
      className={styles.webTable}
      columns={getColumns()}
      pagination={false}
      rowKey="coinCode"
      emptyText={emptyText}
      onRow={(record) => {
        return {
          onClick: (event) => {
            !isEdit && jumpPage(router, record.coinCode);
          }, // 点击行
          'data-buried': 'table-row',
          'data-buried-type': `table-row-${record.coinCode}`
        };
      }}
      rowClassName={styles.rowClassName}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow
        }
      }}
    />
  );
}
export default inject((rootStore) => ({
  tradeStore: rootStore.store.tradeStore
}))(observer(ListWeb));
