import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';

import { arrayMoveImmutable } from 'array-move';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { Favorite } from '@components/Element/Favorite';
import Icon from '@components/Icon';
import { Table } from '@element/index';
import helper from '@utils/helper';
import { formatPrettyValue, I18n } from '@utils/index';

import { jumpPage } from './utils';

import styles from './index.module.css';

const DragHandle = SortableHandle(() => (
  <Icon name="dragTable" className={styles.iconSize} />
));
const SortableItem = SortableElement((props) => <tr {...props} />);
const SortableBody = SortableContainer((props) => <tbody {...props} />);

function ListH5 (props) {
  const router = useRouter();
  const {
    dataSource = [],
    loading,
    type = 'page',
    setDrawerVisible,
    page,
    emptyText,
    hideCoinIcon = false,
    isEdit,
    selecteChange,
    selectedRowKeys,
    onTopping,
    setDataSource,
    showFavorite
  } = props;
  // type: 'drawer' | 'page'
  // page: 'ZONES_PAGE' | 'MARKET_PAGE' | 'HOME_HOT' | 'HOME_NEW' 板块详情 市场列表 首页-热门币种 首页-kiki新币
  console.log(setDrawerVisible, 'setDrawerVisible')
  const columns = [
    {
      title: 'onTitle',
      dataIndex: 'asset',
      key: 'asset',
      className: 'drag-visible',
      render: (_, record) => {
        const { riseAndFall, coinCode, picturePath, volume, currentDataText } = record;
        const [coin = '', currentDataCount = ''] = currentDataText?.split(' ') || []
        const riseAndFallClassName = cn(styles.increase, 'DINPro-Medium', {
          [styles.greenText]: riseAndFall > 0,
          [styles.redText]: riseAndFall < 0
        });
        const coinArr = coinCode?.split('_') ?? [];
        // const isFavorite = zones?.some(i => i.categoryKey === record.coinCode)
        return (
          <div className={styles.flexContainerH5}>
            <div className={styles.flexContainerH5}>
              <div className={styles.coinColH5}>
                <div
                  className={cn(styles.favoriteIcon, {
                    [styles.hide]: !showFavorite || isEdit
                  })}
                >
                  <Favorite
                    className={styles.start}
                    code={record?.coinCode}
                    favoriteType="symbol"
                  />
                </div>
                <div
                  className={cn(styles.coinIconH5, {
                    [styles.hideCoinIcon]: hideCoinIcon
                  })}
                >
                  {picturePath && (
                    <Image
                      src={picturePath}
                      className="image_borderRadius_32"
                      width={32}
                      height={32}
                      layout="fixed"
                      alt={'kikitrade'}
                    />
                  )}
                </div>
                <div className={styles.doubleLineH5}>
                  <div
                    className={cn(styles.firstLineH5, {
                      [styles.lh37]: type === 'drawer' || page === 'HOME_NEW'
                    })}
                  >
                    <span className={cn(styles.coinNameH5, 'DINPro-Medium')}>
                      {page === 'HOME_NEW' ? record.name : coinArr[0]}
                    </span>
                    {page !== 'HOME_NEW' && (
                      <span className={cn(styles.currencyH5, 'DINPro-Medium')}>
                        /{coinArr[1]}
                      </span>
                    )}
                  </div>
                  <div
                    className={cn(styles.coinColBottomH5, styles.secondLineH5, {
                      [styles.displayNone]:
                        type === 'drawer' || page === 'HOME_NEW'
                    })}
                  >
                    <div className={cn(styles.greyTextH5, 'DINPro')}>
                      {I18n.t('qYxQ0WzV')}
                    </div>
                    <div
                      className={cn(
                        styles.greyTextH5,
                        styles.transferData,
                        'DINPro'
                      )}
                    >
                      {volume
                        ? helper.formatVolume(parseFloat(volume).toFixed(2))
                        : '--'}
                    </div>
                  </div>
                </div>
              </div>
              {!isEdit && (
                <div className={cn(styles.latestPriceH5)}>
                  <span className={cn(styles.latestPrice1H5, 'DINPro-Medium')}>
                    {formatPrettyValue(record.formatPriceLast)}
                  </span>
                  <span className={cn(styles.latestPrice2H5, 'DINPro')}>
                    {`${coin} ${formatPrettyValue(currentDataCount)}`}
                  </span>
                </div>
              )}
            </div>

            {!isEdit && (
              <div className={cn(styles.riseAndFallH5)}>
                <span className={riseAndFallClassName}>
                  {riseAndFall > 0 ? '+' : ''}
                  {helper.formatFixNumString(Number(riseAndFall ?? '0'), 2)}%
                </span>
              </div>
            )}
          </div>
        );
      }
    }
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      console.log('Sorted items: ', newData);
      setDataSource(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      (x) => x.coinCode === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const rowSelection = {
    type: 'checkbox',
    hideSelectAll: true,
    onChange: selecteChange,
    selectedRowKeys
  };

  const getColumns = () => {
    if (isEdit) {
      columns.push(
        {
          title: 'onTitle',
          key: 'action1',
          className: 'drag-visible',
          render: (record) => {
            return (
              <Icon
                name="topping"
                className={styles.iconSize}
                onClick={() => onTopping(record.coinCode)}
              />
            );
          }
        },
        {
          title: 'onTitle',
          key: 'action2',
          className: 'drag-visible',
          render: () => {
            return <DragHandle />;
          }
        }
      );
    }
    return columns;
  };

  const className = cn({
    [styles.h5Table]: type === 'page',
    [styles.drawerTable]: type === 'drawer'
  });
  return (
    <Table
      loading={loading}
      dataSource={dataSource}
      className={className}
      columns={getColumns()}
      rowSelection={isEdit ? rowSelection : null}
      pagination={false}
      emptyText={emptyText}
      rowKey="coinCode"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow
        }
      }}
      onRow={(record) => {
        return {
          onClick: (event) => {
            !isEdit && jumpPage(router, record.coinCode);
            type === 'drawer' && setDrawerVisible(false);
          }, // 点击行
          'data-buried': 'table-row',
          'data-buried-type': `table-row-${record.coinCode}`
        };
      }}
    />
  );
}
export default inject((rootStore) => ({
  tradeStore: rootStore.store.tradeStore
}))(observer(ListH5));
